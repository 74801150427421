import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger, SplitText);

const common = {
  isMobile: "",
  animDelay: "",
  linesClass: "lineWrapper__line",
  initLoad() {
    common.isMobile = common.IsMobileDevice();
    common.animDelay = common.setAnimDelay();
    common.setSectionIndex();
    common.setArrowsColor();
    common.mobileHeightFix();
    common.splitTexts();
    common.footerClip()

    setTimeout(() => {
      console.log('anim started after: ' + common.animDelay);
    }, common.animDelay);
  },
  IsMobileDevice() {
    return $(window).outerWidth() < 769;
  },
  mobileHeightFix() {
    if (!common.isMobile) return;
    $(".height--100vh:not(.no-mobile)").height($(window).outerHeight());
  },
  setSectionIndex() {
    if ($(".js-sectionIndex").length == 0) return;
    const sectionIndexs = $(".js-sectionIndex");

    sectionIndexs.each(function (index) {
      // start from 1
      index++;

      $(this).find(".label").text(index);
    });
  },
  // color dynamically arrows (es => home wines )
  setArrowsColor() {
    if ($(".arrowWrapper[data-color]").length == 0) return;
    const arrows = $(".arrowWrapper[data-color]");

    arrows.each(function (index) {
      let arrow = $(this);
      arrow.find("g").css("fill", arrow.data("color"));
    });
  },
  setAnimDelay() {
     return $("#preloader").length ? 1 : .4
  },
  splitTexts() {
    // add in js animations class, so then we ll trigger one
    // single class instead of X different classes
    $(".homeTitle,.titleWrapper__title").addClass("splitText--title");

    // split ...
    $(".splitText--title").each(function () {
      let title = $(this);
      let split = new SplitText(title, {
        type: "lines",
        linesClass: "lineWrapper__line",
      });
      $(title).find("> div").wrap('<div class="lineWrapper" />');
    });
  },
  fixLinesOverflow(el = null) {
    if (el) {
      // console.log("el: ", el.find(".lineWrapper"));
      el.find(".lineWrapper").each(function(i){
        setTimeout(() => {

          $(this).addClass("overflow--visible");
        }, i *200);
      })
    }
  },
  footerClip(){
    const els = $("#page-wines, #page-tenuta, #page-single-wine, #page-who-we-are, #page-single-news");
    if (els.length) $(".footer").addClass("no-clip");

  }
};

export default common;

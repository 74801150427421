const animConfigs = {
  heroTitles: {
    class: "heroTitles",
    from: { yPercent: 100 },
    start: "top 75%",
    end: "+50%",
    markers: false,
    to: {
      yPercent: 0,
      stagger: { each: 0.1, ease: "Power1.easeOut" },
      ease: "Power1.easeOut",
    },
  },
  fadeIn: {
    class: "fadeIn",
    from: { opacity: 0 },
    start: "top 75%",
    end: "+50%",
    markers: false,
    to: {
      ease: "Power2.easeOut",
      duration: 2,
      opacity: 1,
      stagger: { each: 0.15, ease: "Power1.easeOut" },
    },
  },
  fadeInUp: {
    class: "fadeInUp",
    from: { y: 20, opacity: 0 },
    start: "top 75%",
    end: "+50%",
    markers: false,
    to: {
      duration: 0.75,
      opacity: 1,
      y: 0,
      stagger: { each: 0.1, ease: "Power2.easeOut" },
      ease: "Power2.easeOut",
    },
  },
  imageMove: {
    class: "imagesMove",
    from: {
      yPercent: -10,
      scale: 0.7,
      "-webkit-filter": "grayscale(100%)",
      filter: "grayscale(100%)",
    },
    start: "top center",
    end: "+50%",
    markers: false,
    to: {
      duration: 1.3,
      ease: "Power2.easeOut",
      yPercent: 0,
      scale: 1,
      "-webkit-filter": "grayscale(0%)",
      filter: "grayscale(0%)",
    },
  },

  imageVertical: {
    class: "imageVertical",
    from: {
      yPercent: 50,
    },
    start: "top center",
    end: "+50%",
    markers: false,
    to: {
      duration: 1,
      ease: "Power2.easeOut",
      yPercent: 0,
    },
  },

  zoomOut: {
    class: "zoomOut",
    from: { scale: 1.3},
    start: "top center",
    markers: false,
    to: {
      duration: 1,
      ease: "Power3.easeOut",
      scale: 1,
    },
  },
  maskFromLeft: {
    class: "maskFromLeft",
    from: { clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)" },
    start: "top 75%",
    markers: false,
    to: {
      ease: "Power4.easeOut",
      duration:1.3,
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
      stagger: { each: 0.15 },
    },
  },

  wineAnim: {
    class: "wineAnim",
    from: { transformOrigin: "top center", opacity: 0, yPercent: 10 },
    start: "top 75%",
    markers: false,
    to: { stagger: 0.1, opacity: 1, yPercent: 0 },
  },
  imageMaskDezoom: {
    class: "imageMaskDezoom",
    from: {
      scale: 1.2,
      clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
    },
    start: "top 75%",
    markers: false,
    to: {
      scale: 1,
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
      stagger: .2,
    },
  },
};

export default animConfigs;

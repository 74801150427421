import gsap from "gsap";
// import { gsap, ScrollSmoother } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
const scroller = {
  scrollInstance: '',
  initLoad() {
    // create the scrollSmoother before your scrollTriggers
    scroller.scrollInstance = ScrollSmoother.create({
      content: "#main_cont",
      wrapper: "#main",
      smooth: 1.1, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: false, // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });
  }
};

export default scroller;

import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger, SplitText);

const preloader = {
  initReady() {
    preloader.initPreloader();
  },
  initPreloader() {
    gsap.
    timeline({
      onComplete: ()=> $('#preloader').remove()
    })
    .addLabel('start')
    .to("#preloader svg path,#preloader svg polygon", {ease: 'Power2.out', duration:.75,stroke: 'transparent', fill: '#EFEDE9' },'start')
    .to("#preloader", { duration: 0.75, yPercent: -100 },'+=.5');
  },
};

export default preloader;

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import common from "./common";
import animConfigs from "./animConfigs";
import commonAnim from "./commonAnim";
var jQueryBridget = require("jquery-bridget");
var Isotope = require("isotope-layout");

gsap.registerPlugin(ScrollTrigger);

const wines = {
  initLoad() {
    if (!$("#page-wines").length) return;
    // make isotope a Jquey plugin
    jQueryBridget("isotope", Isotope, $);
    if (!common.isMobile) {
      wines.moveOrangeBox();
    }
    wines.optionsSelect();
    wines.animWines();
    wines.addBorder();

    // let filterClass = '.collezione--all-collections.tipologia--all-types'
    // wines.filterWines(filterClass)
  },
  moveOrangeBox() {
    $(".orangeBox").insertAfter($(".singleWine:nth-child(4)"));
  },
  addBorder(filtered = false) {


    $(".singleWine:nth-child(4)").addClass("no-padding");
    $(".singleWine").removeClass("bordered");
    if (filtered) return false;

    $(".singleWine").removeClass("no-padding");
    $(".singleWine:nth-child(2)").addClass("bordered");
    $(".singleWine:nth-child(7)").addClass("bordered");
    $(".singleWine:nth-child(10)").addClass("bordered");
    $(".singleWine:nth-child(13)").addClass("bordered");
    $(".singleWine:nth-child(16)").addClass("bordered");
  },
  optionsSelect() {
    var filterClass = "";
    var attr = "";
    // open
    // let lastOpenedIndex = "";
    $(".optionsWrapper").on("click", function (index) {
      let select = $(this);
      attr = select.attr("id");

      // open new select
      // if (lastOpenedIndex !== select.index())
      select.find(".options").slideToggle();

      // close last one
      $(".optionsWrapper").each(function (i) {
        let optionWrapper = $(this);
        let optionWrapperId = optionWrapper.attr("id");
        console.log(attr, optionWrapperId);
        if (attr !== optionWrapperId) optionWrapper.find(".options").slideUp();
      });
    });
    // filter
    $(".optionsWrapper .options span").on("click", function () {
      // update data
      const $optionsWrapper = $(this).parent().parent();
      $optionsWrapper.find(".selectedOption").text($(this).text());
      $optionsWrapper
        .find(".selectedOption")
        .attr("data-value", $(this).attr("data-value"));

      // find filters
      let collezione =
        ".collezione--" + $("#collezione .selectedOption").attr("data-value");
      let tipologia =
        ".tipologia--" + $("#tipologia .selectedOption").attr("data-value");
      filterClass = collezione + tipologia;

      wines.filterWines(filterClass);
    });
  },
  iso: "",
  filterWines(filterClass = null) {
    if (!wines.iso) {
      wines.iso = $(".list__winesWrapper").isotope({
        itemSelector: ".childElement",
        layoutMode: "fitRows",
      });
    }
    wines.iso.isotope({ filter: filterClass });

    wines.addBorder(true);

    ScrollTrigger.refresh();
  },
  animWines() {
    const tl = gsap
      .timeline()
      .delay(common.animDelay)
      .addLabel("start")
      .fromTo(
        ".filters__titles p",
        animConfigs.fadeInUp.from,
        animConfigs.fadeInUp.to,
        "start"
      )
      .fromTo(
        ".filters__wrapperInner .selectedOption",
        animConfigs.maskFromLeft.from,
        animConfigs.maskFromLeft.to,
        "start+=.2"
      );

    if (common.isMobile) {
      $(".singleWine").each(function () {
        let wine = $(this);
        gsap
          .timeline({
            scrollTrigger: {
              trigger: wine,
              start: "top 75%",
              markers: false,
            },
          })
          .delay(common.animDelay)
          .fromTo(
            wine.find(".singleWine__imageWrapper"),
            animConfigs.wineAnim.from,
            animConfigs.wineAnim.to,
            "start+=.3"
          )
          .fromTo(
            wine.find(".singleWine__dataWrapper > div"),
            animConfigs.fadeInUp.from,
            animConfigs.fadeInUp.to,
            "start+=.3"
          )
          .fromTo(
            wine.find(".singleWine__dataWrapper .button"),
            animConfigs.fadeInUp.from,
            animConfigs.fadeInUp.to,
            "start+=.3"
          );
      });
    } else {
      tl.fromTo(
        ".singleWine__imageWrapper ",
        animConfigs.wineAnim.from,
        animConfigs.wineAnim.to,
        "start+=.3"
      ).fromTo(
        ".singleWine__dataWrapper > div, .singleWine__dataWrapper .button",
        animConfigs.fadeInUp.from,
        animConfigs.fadeInUp.to,
        "start+=.3"
      );
    }

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".orangeBox",
          start: "top 60%",
          markers: false,
        },
      })
      .fromTo(
        ".orangeBox",
        animConfigs.maskFromLeft.from,
        animConfigs.maskFromLeft.to
      );
  },
};

export default wines;

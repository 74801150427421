import $ from "jquery";
import "../sass/main.scss";
import menu from "./menu";
import scroller from "./scroller";
import lazyLoad from "./lazyLoad";
import smoothState from "./smoothState";
import animations from "./animations";
import preloader from "./preloader";
import common from "./common";
import commonAnim from "./commonAnim";
import home from "./home";
import whoWeAre from "./who-we-are";
import tenuta from "./tenuta";
import wines from "./wines";
import singleWine from "./singleWine";
import comingSoon from './3deffect';

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.$ = require("jquery");
window.jQuery = require("jquery");

const siteGlobal = {
  activateAnim: true,
  initReady() {
    smoothState.initReady();
    preloader.initReady()
  },
  initLoad() {
    // wait 500ms in order to load properly
    // the content of page.
    // without timout, scroller.initLOad() doesnt
    // get correctly page height
    // setTimeout(() => {
    scroller.initLoad();
    // lazyLoad.initLoad();
    menu.initLoad();
    common.initLoad();
    comingSoon.init()

    // debug purpose only
    if (siteGlobal.activateAnim) {
      home.initLoad();
      whoWeAre.initLoad();
      tenuta.initLoad();
      wines.initLoad();
      singleWine.initLoad();
      commonAnim.initLoad();
    }

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 500);

    // setTimeout(() => {
    //   scroller.scrollInstance
    // }, timeout);
  },
  rebuildAllEvents() {
    siteGlobal.initLoad();
  },
  initScroll() {

    const windowY = $(window).scrollTop()

    windowY > $(window).outerHeight() ? $('.header').addClass('fixed') :  $('.header').removeClass('fixed')

  },
  initResize() {},
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on("rebuild", () => {
  siteGlobal.rebuild();
});

$(window).on("load", () => {
  $("#preloader").addClass("loaded");
  // setTimeout(() => {
  //   $("#preloader").remove();
  // }, 500);

  siteGlobal.initLoad();
});

$(window).on("resize", () => {
  siteGlobal.initResize();
});

$(window).on("scroll", () => {
  siteGlobal.initScroll();
});

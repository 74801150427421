import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import configs from "./animConfigs";

gsap.registerPlugin(ScrollTrigger);

const animations = {
  initLoad() {
    this.configAnims();
    ScrollTrigger.refresh();
  },
  configAnims() {
    configs.forEach((config) => {
      let configClass = `.${config.class}`;
      let configFrom = config.from;
      let configStart = config.start;
      let configMarkers = config.markers;
      let configTo = config.to;

      if ($(configClass).length > 0) {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: configClass,
              start: configStart,
              markers: false,
              start: "top center",
            },
          })
          .fromTo($(configClass), configFrom, configTo);
      }
    });
  },
};

module.exports = animations;

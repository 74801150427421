import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import common from "./common";
import animConfigs from "./animConfigs";
gsap.registerPlugin(ScrollTrigger);

const commonAnim = {
  markers: false,
  initLoad() {
    setTimeout(() => {
      commonAnim.hero();
      commonAnim.titleWrapper();
      commonAnim.imagesMove();
      commonAnim.sectionIndexes();
      commonAnim.textContent();
      commonAnim.imageVertical();
      commonAnim.newsBlocks();
      commonAnim.nextPages();
      commonAnim.titleLine();
      commonAnim.imagesMaskDezoom();
    }, common.animDelay * 1000);

  },
  elExists(selector) {
    return $(selector).length > 0;
  },
  hero() {
    if (!commonAnim.elExists(".hero__wrapper")) return;

    let elWithLines =  $(".homeTitle").length > 0  ? $(".homeTitle") : $(".hero .contentWrapper__title");

    gsap
      .timeline()
      .addLabel("start")
      .fromTo(
        ".hero__wrapper canvas",
        animConfigs.zoomOut.from,
        animConfigs.zoomOut.to,
        "start"
      )
      .fromTo(
        ".hero__wrapper ." + common.linesClass,
        animConfigs.heroTitles.from,
        animConfigs.heroTitles.to,
        "start+=.4"
      )
      .call(common.fixLinesOverflow, [elWithLines], "start+=.8");
  },
  titleLine() {
    if (!commonAnim.elExists(".animTitleLine")) return;

    $(".animTitleLine").each(function () {
      let el = $(this);
      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: "top 75%",
            end: "top center",
            markers: commonAnim.markers,
            scrub: commonAnim.markers,
          },
        })
        .addLabel("start")
        .fromTo(
          el,
          animConfigs.maskFromLeft.from,
          animConfigs.maskFromLeft.to,
          "start"
        )
        .fromTo(
          el.find(">h2"),
          animConfigs.fadeIn.from,
          animConfigs.fadeIn.to,
          "start+=.3"
        );
    });
  },
  imagesMove() {
    $(".animImageMove").each(function () {
      let el = $(this);
      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: "top center",
            end: "+=50%",
            markers: commonAnim.markers,
            scrub: commonAnim.markers,
          },
        })
        .addLabel("start")
        .fromTo(
          el.children(),
          animConfigs.imageMove.from,
          animConfigs.imageMove.to,
          "start"
        );
    });
  },
  titleWrapper() {
    if (!commonAnim.elExists(".titleWrapper")) return;

    $(".titleWrapper").each(function () {
      let el = $(this);
      let overflowDelay = el.find(".lineWrapper").length * 0.2;

      gsap
        .timeline({
          onComplete: function () {
            // common.fixLinesOverflow(el)
          },
          scrollTrigger: {
            trigger: el,
            start: "top 80%",
            markers: false,
          },
        })
        .addLabel("start")
        .fromTo(
          el.find(".titleWrapper__subtitle"),
          animConfigs.fadeIn.from,
          animConfigs.fadeIn.to,
          "start"
        )
        .fromTo(
          el.find("." + common.linesClass),
          animConfigs.heroTitles.from,
          animConfigs.heroTitles.to,
          "start+=.2"
        )
        .call(common.fixLinesOverflow, [el], overflowDelay);
    });
  },
  sectionIndexes() {
    if (!commonAnim.elExists(".js-sectionIndex")) return;

    $(".js-sectionIndex:not(.noAnim)").each(function () {
      let el = $(this);
      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: "top center",
            end: "+=100px",
            markers: false,
          },
        })
        .addLabel("start")
        .fromTo(el, animConfigs.fadeIn.from, animConfigs.fadeIn.to, "start");
    });
  },
  textContent() {
    if (!commonAnim.elExists(".animTextContent")) return;
    const els = $(".animTextContent");

    const start = common.isMobile ?  "top 75%" :  "top center"

    els.each(function () {
      let el = $(this);
      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: start,
            markers: false,
          },
        })
        .fromTo(el, animConfigs.fadeIn.from, animConfigs.fadeIn.to);
    });
  },
  imageVertical() {
    if (!commonAnim.elExists(".animImageVertical")) return;
    $(".animImageVertical").each(function () {
      let el = $(this);
      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "+=100%",
            markers: commonAnim.markers,
            scrub: true,
          },
        })
        .addLabel("start")
        .fromTo(
          el.find("img"),
          animConfigs.imageVertical.from,
          animConfigs.imageVertical.to,
          "start"
        );
    });
  },
  imagesMaskDezoom() {
    $(".animImagesMaskDezoom").each(function () {
      let el = $(this);

      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: "top center",
            end: "+=50%",
            markers: commonAnim.markers,
            scrub: commonAnim.markers,
          },
        })
        .addLabel("start")
        .fromTo(
          el.find("img"),
          animConfigs.imageMaskDezoom.from,
          animConfigs.imageMaskDezoom.to,
          "start"
        );
    });
  },
  newsBlocks() {
    if (!commonAnim.elExists(".newsBlock")) return;

    let el = $(this);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".newsBlock__wrapper",
          start: "top bottom",
          markers: false,
        },
      })
      .addLabel("start")
      .fromTo(
        ".newsBlock",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start"
      )
      .fromTo(
        ".lastNews__content > a",
        animConfigs.fadeInUp.from,
        animConfigs.fadeInUp.to,
        "start+=.5"
      );
  },
  nextPages() {
    if (!commonAnim.elExists(".nextPage")) return;

    let el = $(this);
    const initialClip = $(".nextPage .nextPage__imageClipper img").css(
      "clip-path"
    );
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".nextPage",
        start: "top center",
        markers: false,
        scrub: false,
      },
    });

    if (commonAnim.elExists("#page-single-wine")) {
      tl.fromTo(
        ".nextPage .nextPage__imageClipper img, .nextPage__content h3, .nextPage__content > div",
        { opacity: 0 },
        { opacity: 1, stagger: 0.1 }
      );
    } else {
      tl.fromTo(
        ".nextPage .nextPage__imageClipper img",
        { clipPath: initialClip },
        {
          duration: 1.5,
          ease: "Power2.out",
          clipPath: "ellipse(100% 100% at 50% 40%)",
        }
      );
    }
  },
};

export default commonAnim;

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import common from "./common";
import configs from "./animConfigs";
import animConfigs from "./animConfigs";
import commonAnim from "./commonAnim";
gsap.registerPlugin(ScrollTrigger);

const whoWeAre = {
  initLoad() {
    if (!$("#page-who-we-are").length) return;
    whoWeAre.gallerySectionsPin();
  },
  gallerySectionsPin() {
    // pin sections
    const section = $(".gallery__sectionsWrapper");
    let animSection;

    const tl = gsap
      .timeline({
        scrollTrigger: {
          onComplete: function(){
            ScrollTrigger.refresh()
          },
          onLeave: function () {
            gsap.fromTo(
              ".header",
              { opacity: 0 },
              {
                opacity: 1,
              }
            );
          },
          trigger: section,
          start: "top top",
          end: "+400%",
          pin: true,
          pinSpacing: true,
          markers: false,
          scrub: true,
        },
      })
      .to(".gallery__top", { autoAlpha: 1 });

    // anim single sections
    animSection = $(".gallery__section:first-child");
    whoWeAre.firstSectionAnim(animSection, tl);
    whoWeAre.clipSection(animSection, tl);

    animSection = $(".gallery__section:nth-child(2)");
    whoWeAre.secondSectionAnim(animSection, tl);
    whoWeAre.clipSection(animSection, tl);

    animSection = $(".gallery__section:nth-child(3)");
    whoWeAre.thirdSectionAnim(animSection, tl);
    whoWeAre.clipSection(animSection, tl);

    animSection = $(".gallery__section:nth-child(4)");
    whoWeAre.fourthSectionAnim(animSection, tl);
  },
  clipSection(section, tl) {
    tl.fromTo(
      section,
      { clipPath: "inset(0 0 0 0)" },
      { clipPath: "inset(0 0 100% 0)" }
    );
  },
  firstSectionAnim(section, tl) {
    const headerTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: section,
          start: "top top",
          end: "+=200px",
          scrub: true,
        },
      })
      .addLabel("start")
      .fromTo(".header", { opacity: 1 }, { opacity: 0 }, "start")
      // title -- just first time
      .fromTo(
        ".gallery__titleWrapper",
        { opacity: 0 },
        {
          opacity: 1,
        },
        "start"
      );

    // image
    tl.fromTo(
      section.find(".sectionImage img"),
      { opacity: 0.3, scale: 0.6 },
      {
        opacity: 1,
        scale: 1,
      }
    );
  },
  secondSectionAnim(section, tl) {
    tl.addLabel("start");

    tl.fromTo(
      section.find("#letters"),
      { "clip-path": "inset(100% 0 0 0)" },
      {
        "clip-path": "inset(0% 0 0 0)",
      },
      "start"
    ).fromTo(
      section.find(".sectionImage svg"),
      { scale:1 },
      {
        scale: .65,
      }
    );
  },
  thirdSectionAnim(section, tl) {
    tl.fromTo(
      section.find(".sectionImage svg"),
      { scale: 1.1 },
      {
        scale: 1.25,
      }
    );
  },
  fourthSectionAnim(section, tl) {
    tl.fromTo(
      section.find(".sectionImage img"),
      { scale: 1.4, yPercent: -25 },
      {
        scale: 1,
        yPercent: 0,
      }
    );
  },
};

export default whoWeAre;

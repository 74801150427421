import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "slick-carousel";
import common from "./common";
import animConfigs from "./animConfigs";
import commonAnim from "./commonAnim";
gsap.registerPlugin(ScrollTrigger);

const tenuta = {
  initLoad() {
    if (!$("#page-tenuta").length) return;

    // vitigni section
    let firstActiveVitigno = $(".vitigni__filter:first-child").data("vitigno");
    tenuta.updateVitignoData(firstActiveVitigno);
    tenuta.vitigniFilter();
    tenuta.animMap();
    tenuta.animCertifications();
    tenuta.cartinaShow();

    if (common.isMobile) {
      tenuta.certificationsSlider();
      tenuta.mapUpdate();
      tenuta.mapSelect();
    }
  },
  vitigniFilter() {
    $(".vitigni__filter").on("click", function () {
      let clickedVitigno = $(this).data("vitigno");
      tenuta.updateVitignoData(clickedVitigno);
    });

    $(".mapWrap svg polygon").on("click", function () {
      let clickedVitigno = $(this).data("vitigno");
      tenuta.updateVitignoData(clickedVitigno);

      let activeFilter = $(
        '.mobilefiltersWrap .choicesWrapper .vitigni__filter[data-vitigno="' +
          clickedVitigno +
          '"]'
      );
      tenuta.mapUpdate(activeFilter);
    });
  },
  updateVitignoData(vitigno = "pinot-bianco") {
    // removeALL
    $(
      ".vitigni__filter, .vitigni__singleContent, .mapWrap svg polygon"
    ).removeClass("active");

    let activeFilter = $(
      '.filtersWrap .vitigni__filter[data-vitigno="' + vitigno + '"]'
    );
    let activeContent = $(
      '.vitigni__singleContent[data-vitigno="' + vitigno + '"]'
    );
    let activeMap = $('.mapWrap svg polygon[data-vitigno="' + vitigno + '"]');

    //  gsap fill transparent remove
    activeMap.removeAttr("style");

    // set active
    activeFilter.addClass("active");
    activeContent.addClass("active");
    activeMap.addClass("active");

    // change text
    let vitignoName = activeFilter.find(".name").text();
    let vitignoDescription = $(
      '.vitigni__descriptions[data-vitigno="' + vitigno + '"]'
    );
    $(".vitigni__singleContent h3").hide().text(vitignoName).fadeIn("slow");
    $(".vitigni__singleContent p")
      .hide()
      .html(vitignoDescription.html())
      .fadeIn("slow");

    // fill map color
  },
  animCertifications() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".sustainability__end",
          start: "top center",
          markers: false,
        },
      })
      .fromTo(
        ".sustainability__end .slider img",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to
      );
  },
  certificationsSlider() {


    setTimeout(() => {
      const slider = $(".sustainability__certifications .slider");
      slider.slick({
        infinite: true,
        slidesToShow: 1,
        adaptiveHeight: false,
        // fade:true,
        slidesToScroll: 1,
        appendArrows: ".sustainability__certifications .sliderWrap__arrows",
        nextArrow:
          '<div class="sliderWrap__arrows--next"><span class="arrowWrapperBackground"></span></div>',
        prevArrow:
          '<div class="sliderWrap__arrows--prev"><span class="arrowWrapperBackground"></span></div>',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              adaptiveHeight: true,
            },
          },
        ],
      });
    }, 500);

  },
  cartinaShow() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".intro__cartina",
          start: "bottom bottom",
          end: "+=130%",
          markers: false,
          pin: ".intro__middle",
          scrub: true,
          pinSpacing: true,
        },
      })
      .fromTo(".cartina__child", { scale: 1 }, { scale: 3 })
      .fromTo("#Friuli *:not(#Stroke-17)", { opacity: 0 }, { opacity: 1 })
      .fromTo(
        "#Tenuta-Bosco-Albano #Stroke-3,#Tenuta-Bosco-Albano #tba-friuli-line",
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(
        "#Tenuta-Bosco-Albano #Stroke-4_1_,#Tenuta-Bosco-Albano #tba-text-line, #tba-text-line text, #CHI-SIAMO ",
        { opacity: 0 },
        { opacity: 1 }
      );
    // .fromTo('#Tenuta-Bosco-Albano *:not(#Stroke-3):not(#Stroke-4)', {opacity: 0},{opacity:1})
  },
  animMap() {
    const regions = $(".map svg polygon:not(#map-icon)");
    regions.addClass("active");
    gsap
      .timeline({
        onComplete: function () {
          regions.removeClass("active");
        },
        scrollTrigger: {
          trigger: ".vitigni__inner",
          start: "top 40%",
          end: "+=50%",
          markers: false,
          // scrub: true
        },
      })
      .addLabel("start")
      .fromTo(
        "p.details",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start-=.2"
      )
      .fromTo(
        ".vitigni__inner .filtersWrap .vitigni__filter",
        animConfigs.fadeInUp.from,
        animConfigs.fadeInUp.to,
        "start"
      )
      .fromTo(
        ".vitigni__singleContent h3,.vitigni__singleContent p,.vitigni__singleContent .allvitigniButton ",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start+=.25"
      )
      .to(
        regions,
        { stagger: 0.05, fill: "transparent", ease: "Power2.out" },
        "start"
      );
  },
  mapSelect() {
    $(".mobilefiltersWrap .activeChoice").on("click", function () {
      $(this).toggleClass("active");
      $(".mobilefiltersWrap .choicesWrapper").slideToggle();
    });
    $(".mobilefiltersWrap .choicesWrapper .vitigni__filter").on(
      "click",
      function () {
        tenuta.mapUpdate($(this));
        $(".mobilefiltersWrap .choicesWrapper").slideToggle();
      }
    );
  },
  mapUpdate(choice = null) {
    if (!choice)
      choice = $(
        ".mobilefiltersWrap .choicesWrapper .vitigni__filter:first-child"
      );

    $(".mobilefiltersWrap .activeChoice .title").html(choice.clone());
  },
};

export default tenuta;

import { gsap } from "gsap";
const menu = {
  initLoad() {
    // reset at white color
    $(".header").removeClass("dark");

    menu.menuCanAnim = true;
    this.menuInit();
    this.langSelect();
    this.headerNeedColor();
    this.scrollTopHeaderColor();
  },

  menuInit() {
    $(".header__hamburger").on("click", () => {
      if (menu.menuCanAnim) {
        $(".header__hamburger, .header").toggleClass("opened");
        menu.menuAnim();
      }
    });
  },
  langSelect() {
    $(".header__menuContent .langs").on("click", function () {
      let langsElement = $(this);
      let langsList = langsElement.find("ul");

      // is visible?
      if (langsList.is(":visible"))
        langsElement.removeClass("active"), langsList.slideUp(350);
      else langsElement.addClass("active"), langsList.slideDown(350);
    });
  },
  headerNeedColor() {
    const els = $(
      "#page-wines, #page-single-wine, #page-news, #page-contacts,#page-single-news, #page-contacts"
    );
    if (els.length) $(".header").addClass("dark");
  },
  setHaderColor(section) {
    if (section.hasClass("bg-white")) {
      $(".header").addClass("dark");
    } else {
      $(".header").removeClass("dark");
    }
  },
  scrollTopHeaderColor() {
    $("#main_cont section").each(function () {
      let section = $(this);

      gsap.timeline({
        scrollTrigger: {
          onEnter: function () {
            menu.setHaderColor(section);
          },
          onEnterBack: function () {
            menu.setHaderColor(section);
          },
          start: "top top",
          trigger: section,
          // markers: true,
        },
      });
    });
  },
  menuCanAnim: true,
  menuAnim() {
    const tl = gsap
      .timeline({
        onStart: function () {
          menu.menuCanAnim = false;
        },
        onComplete: function () {
          menu.menuCanAnim = true;
        },
      })
      .addLabel("start")
      .fromTo(
        ".header__menuContent",
        { duration: 0.5, display: "none", opacity: 0 },
        { duration: 0.5, display: "flex", opacity: 1 }
      )
      .fromTo(
        "#headerMenu li a",
        { yPercent: 100 },
        { yPercent: 0, stagger: 0.1 },
        "start"
      )
      .fromTo(
        ".contactMenu__title ",
        { opacity: 0 },
        { opacity: 1 },
        "start+=.2"
      )
      .fromTo(
        ".header__contactMenu span,.header__contactMenu .content ",
        { opacity: 0 },
        { opacity: 1, stagger: 0.1 },
        "start+=.4"
      )
      .fromTo(
        ".certification,.general, .langs ",
        { opacity: 0 },
        { opacity: 1, stagger: 0.1 },
        "start+=.7"
      );

    if ($(".header").hasClass("opened")) tl.play();
    else tl.reverse(0);
  },
};

module.exports = menu;

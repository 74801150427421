import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import common from "./common";
import animConfigs from "./animConfigs";
import commonAnim from "./commonAnim";

gsap.registerPlugin(ScrollTrigger);

const singleWine = {
  initLoad() {
    if (!$("#page-single-wine").length) return;

    singleWine.animScene();
    singleWine.openVoices();
  },
  openVoices() {
    $(".propsTitle").on("click", function () {
      let clicked = $(this).parent();
      clicked.toggleClass("opened");
      clicked.find(".propsGroup").slideToggle(400, function () {
        ScrollTrigger.refresh();
      });
    });
  },
  animScene() {
    const ease = "Power2.out";
    // const xHalf =  common.isMobile ? 0 : $(window).outerWidth() / 4 + "px";
    const xHalf = 0;
    const imgScale = common.isMobile ? 1.2 : 1.8
    const imgY = common.isMobile ? -30 : -70

    const pin = common.isMobile ? false :  ".imageWrapperInner"
    const tl = gsap
      .timeline({
        onComplete: function () {
          ScrollTrigger.refresh();
          // pin wine
          gsap.timeline({
            scrollTrigger: {
              trigger: ".imageWrapper",
              start: "top 15%",
              end: "+=75%",
              // end: "+=" + $(".content__wrapper").outerHeight(),
              markers: false,
              pin: pin,
              pinSpacing: false,
            },
          });
        },
      })
      .set(
        ".marqueeWrapper > svg,.marqueeWrapper .track,.shortDescription, .content__top .marqueeWrapper h3,.scrolldownButton",
        { opacity: 0, duration: 0 }
      )
      .delay(common.animDelay)
      .addLabel("start")

      // show track
      .to(
        ".marqueeWrapper .track",
        {
          duration: 0.5,
          opacity: 1,
        },
        "start+=.5"
      )
      // move track
      .addLabel("marquee")
      .fromTo(
        ".marqueeWrapper .track",
        {
          xPercent: 30,
        },
        {
          ease: "none",
          xPercent: 0,
          duration: 2.5,
        },
        "start+=1.1"
      )
      // show marquee and color
      .addLabel("colorMarquee")
      .to(
        ".singleWine__marquee:not(.centered),.singleWine__marquee svg",
        {
          duration: 0.5,
          opacity: 0,
        },
        "colorMarquee"
      )
      .to(
        ".singleWine__marquee.centered span",
        {
          duration: 1.5,
          color: "#212726",
        },
        "colorMarquee+=.3"
      )
      .fromTo(
        ".marqueeWrapper > svg",
        {
          rotate: 360,
        },
        {
          duration: 1,
          rotate: 0,
          opacity: 1,
        },
        "colorMarquee+=.3"
      )
      .to(
        ".content__top, .content__top .marqueeWrapper h3",
        {
          duration: 1,
          opacity: 1,
        },
        "colorMarquee+=.7"
      )
      .to(
        ".marqueeWrapper > svg polygon",
        {
          fill: "#212726",
        },
        "colorMarquee+=.45"
      )
      // show wine image
      .to(
        ".imageWrapper",
        {
          duration: 0.5,
          opacity: 1,
        },
        "marquee+=.5"
      )
      // scale wine image
      .fromTo(
        ".imageWrapper .imageWrapperInner img",
        { scale: imgScale, x: xHalf, yPercent: imgY },
        {
          ease: "Power3.out",
          duration: 1.3,
          scale: 1,
          x: 0,
          yPercent: 0,
        },
        "marquee+=.8"
      )
      // move wine image
      // .to(
      //   ".imageWrapper .imageWrapperInner",
      //   {
      //     ease: ease,
      //     duration: 1,
      //     x: 0,
      //   },
      //   "marquee+=2"
      // )
      // show shortDescription
      .fromTo(
        ".shortDescription",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "marquee+=2.5"
      )
      .fromTo(
        ".scrolldownButton",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "marquee+=2.8"
      );

    if (common.isMobile) return;
  },
};

export default singleWine;

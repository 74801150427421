import "jquery.marquee";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "slick-carousel";
import common from "./common";
import animConfigs from "./animConfigs";
import commonAnim from "./commonAnim";

gsap.registerPlugin(ScrollTrigger);

const home = {
  initLoad() {
    if (!$("#page-home").length) return;

    home.heroSectionsOverlap();

    setTimeout(() => {
      // wines section
      home.winesSlider();

      let firstActiveWine = $(".slider")
        .find(".slick-active .wines__slide")
        .attr("data-wine");
      home.updateWineData(firstActiveWine, true);
      home.winesFilter();
    }, 500);

    // anim
    home.animIntro();
    setTimeout(() => {
      home.animWines();
    }, 500);
    home.animImagesGrid();
    home.animInspire();

    home.winesFilterMarquee();
  },
  winesFilterMarquee() {
    $(".wines__content .filtersWrap__scroller").marquee({
      allowCss3Support: true,
      // css3easing: 'ease-in',
      //duration in milliseconds of the marquee
      duration: 45000,
      //gap in pixels between the tickers
      // gap: 50,
      //time in milliseconds before the marquee will start animating
      delayBeforeStart: 0,
      //'left' or 'right'
      direction: "up",
      //true or false - should the marquee be duplicated to show an effect of continues flow
      duplicated: true,
      // pauseOnHover: true
      startVisible: true,
    });
  },
  heroSectionsOverlap() {
    if ($("#page-home").length == 0) return;

    const MARKERS = false;

    gsap
      .timeline()
      .delay(0.8)
      .fromTo(
        "#page-home .homeTitle ." + common.linesClass,
        animConfigs.heroTitles.from,
        animConfigs.heroTitles.to
      );

    // pin background
    gsap.timeline({
      scrollTrigger: {
        trigger: "#page-home .hero",
        start: "top top",
        end: "bottom top",
        pin: "#page-home .hero__wrapper",
        pinSpacing: false,
        scrub: MARKERS,
        markers: MARKERS,
      },
    });

    let end = common.isMobile ? "top 25%" : "top center";

    // pin title
    gsap.timeline({
      scrollTrigger: {
        onEnterBack: function () {
          gsap.to("#page-home .homeTitle .hidden", { autoAlpha: 0 });
        },
        onLeave: function () {
          gsap.to("#page-home .homeTitle .hidden", { autoAlpha: 1 });
        },
        trigger: "#page-home .hero",
        start: "top top",
        end: end,
        endTrigger: "#page-home .intro",
        pin: "#page-home .homeTitle",
        pinSpacing: false,
        markers: MARKERS,
      },
    });
  },
  winesSlider() {
    const slider = $(".sliderWrap .slider");
    slider.slick({
      infinite: true,
      slidesToShow: 1,
      adaptiveHeight: false,
      prevArrow: false,
      swipe: true,
      fade: true,
      slidesToScroll: 1,
      appendArrows: ".sliderWrap__arrows",
      nextArrow:
        '<div class="sliderWrap__arrows--next hoverMove"><span class="c-white t-20 t-medium">Next</span><span class="arrowWrapperBackground move"></span></div>',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            nextArrow:
              '<div class="sliderWrap__arrows--next hoverMove"><span class="arrowWrapperBackground move"></span></div>',
            prevArrow:
              '<div class="sliderWrap__arrows--prev hoverMove"><span class="arrowWrapperBackground move"></span></div>',
          },
        },
      ],
    });

    // On swipe event
    slider.on("swipe", function (event, slick, direction) {
      // wait slick to add active class
      setTimeout(() => {
        let clickedWine = slider
          .find(".slick-active .wines__slide")
          .data("wine");
        home.updateWineData(clickedWine);
      }, 200);
    });

    // On click event
    $(".sliderWrap__arrows").on("click", function () {
      let clickedWine = slider.find(".slick-active .wines__slide").data("wine");
      home.updateWineData(clickedWine);
    });
  },
  winesFilter() {
    $(".filtersWrap .wines__filter").on("click", function () {
      let clickedWine = $(this).data("wine");
      home.updateWineData(clickedWine, true);
    });
  },
  updateWineData(wine = false, changeSlide = false) {
    // removeALL
    $(".wines__filter, .wines__singleContent, .marqueeWrap .track").removeClass(
      "active"
    );

    const slider = $(".sliderWrap .slider");
    let activeColor = $('.wines__color[data-wine="' + wine + '"]');
    let activeFilter = $('.wines__filter[data-wine="' + wine + '"]')
    let activeContent = $('.wines__singleContent[data-wine="' + wine + '"]');
    let activeMarquee = $('.marqueeWrap .track[data-wine="' + wine + '"]');

    // set active
    activeFilter.addClass("active");
    activeContent.addClass("active");
    activeMarquee.addClass("active");

    gsap.to(".wines__circle", { background: activeColor.eq(0).data("color") });

    if (changeSlide) {
      let clickedWineIndex = $('.wines__slide[data-wine="' + wine + '"]')
        .last()
        .parent()
        .parent()
        .attr("data-slick-index");

      slider.slick("slickGoTo", clickedWineIndex);
    }
  },
  animIntro() {
    const MARKERS = false;
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".intro__text",
          start: "top center",
          end: "+=100%",
          markers: MARKERS,
          scrub: MARKERS,
        },
      })
      .addLabel("start")
      .fromTo(
        ".intro__text p,.intro__text .button",
        animConfigs.fadeInUp.from,
        animConfigs.fadeInUp.to,
        "start"
      );
  },
  animWines() {
    const MARKERS = false;

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".wines__content",
        start: "top 60%",
        markers: MARKERS,
        scrub: MARKERS,
      },
    });

    tl.addLabel("start");
    tl.fromTo(
      ".wines__singleWine .marqueeWrap",
      animConfigs.fadeIn.from,
      animConfigs.fadeIn.to,
      "start"
    );
    tl.fromTo(
      ".wines__singleWine .wines__filter",
      animConfigs.fadeInUp.from,
      animConfigs.fadeInUp.to,
      "start"
    );
    if (common.isMobile) {
      tl.fromTo(
        ".wines__circle",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start+=.25"
      );
    } else {
      tl.fromTo(
        ".wines__circle",
        { clipPath: "circle(0% at 50% 50%)" },
        {
          duration: 2,
          ease: "Power2.out",
          clipPath: "circle(100% at 50% 50%)",
        },
        "start+=.25"
      );
    }
    tl.fromTo(
      ".wines__singleWine .wines__singleContent.active p,.wines__singleWine .wines__singleContent.active .buttonsWrapper ",
      animConfigs.fadeIn.from,
      animConfigs.fadeIn.to,
      "start+=.25"
    );
    tl.fromTo(
      ".wines__singleWine .sliderWrap .slider",
      { opacity: 0, xPercent: -30 },
      { opacity: 1, xPercent: 0 },
      "start+=.4"
    );
    tl.fromTo(
      ".wines__singleWine .sliderWrap .sliderWrap__arrows",
      animConfigs.fadeIn.from,
      animConfigs.fadeIn.to,
      "start+=1"
    );
    tl.fromTo(
      ".wines__endButton",
      animConfigs.fadeIn.from,
      animConfigs.fadeIn.to,
      "start+=.1.5"
    );
  },
  animInspire() {
    const MARKERS = false;
    let clippedTextH = $(".clippedText").outerHeight();
    let clippedTextW = $(".clippedText").outerWidth();
    let clippedTextClip = $(".clippedText").css("clip-path");
    let circleColor = $(".inspire__circle").css("background-color");

    // let start = common.isMobile ? "center center" : "center center"
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".inspire__circle",
          start: 'center 50%"',
          end: "+=75%",
          pin: ".inspire__contentWrap",
          pinSpacing: true,
          markers: false,
          scrub: true,
        },
      })
      .addLabel("start")
      .fromTo(
        ".inspire__inner > svg",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start"
      )
      .fromTo(
        ".inspire__circle",
        { scale: 0.3 },
        { duration: 1, scale: 1, background: circleColor },
        "start"
      )
      .fromTo(
        ".clippedText",
        { clipPath: clippedTextClip },
        {
          clipPath:
            "ellipse(" + clippedTextH + "px " + clippedTextW + "px at 50% 50%)",
        },
        "start"
      )
      .fromTo(
        ".inspire__inner .button",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start+=.5"
      );
  },
  animImagesGrid() {
    const MARKERS = false;

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".imagesGrid__content",
          start: "top center",
          end: "+=50%",
          markers: MARKERS,
          scrub: MARKERS,
        },
      })
      .addLabel("start")
      .fromTo(
        ".imagesGrid__content .textWrap p",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start+=.1"
      )
      .fromTo(
        ".imagesGrid__content .textWrap .button",
        animConfigs.fadeIn.from,
        animConfigs.fadeIn.to,
        "start+=.2"
      );
  },
};

export default home;

import smoothState from "smoothState";
import gsap from "gsap";

const pageTransition = {
  initReady() {
    this.initPageTransition();
  },

  initPageTransition() {
    const options = {
      anchors: "a",
      forms: "form",
      allowFormCaching: false,
      scroll: true,
      blacklist: ".no-smoothState , .no-smoothState > a, .wpcf7-form",
      prefetch: true,
      cacheLength: 20,
      onBefore: function ($currentTarget, $container) {},
      onStart: {
        duration: 400,
        render: function ($container) {

          let duration = 0.4;
          let ease = "Power2.out";
          gsap
            .timeline()
            .addLabel("start")
            .set("#pageTransitionerOne,#pageTransitionerTwo", {
              display: "block",
            })
            .fromTo(
              "#pageTransitionerOne",
              { transform: "translateY(100%)" },
              { ease: ease, duration: duration, transform: "translateY(0%)" },
              "start"
            )
            .fromTo(
              "#pageTransitionerTwo",
              { transform: "translateY(100%)" },
              {
                ease: ease,
                duration: duration + 0.2,
                transform: "translateY(0%)",
              },
              "start+=.3"
            )
            .addLabel("middle")
            .to(
              "#pageTransitionerTwo",
              {
                ease: ease,
                duration: duration,
                transform: "translateY(-100%)",
              },
              "middle-=.3"
            )
            .to(
              "#pageTransitionerOne",
              {
                ease: ease,
                duration: duration + 0.2,
                transform: "translateY(-100%)",
              },
              "middle"
            )
            .set("#pageTransitionerOne,#pageTransitionerTwo", {
              display: "none",
            });

          if ($(".header__hamburger").hasClass("opened")) {
            $(".header__hamburger, .header, .header__menu").removeClass(
              "opened"
            );
            setTimeout(function () {
              // $("#main").addClass("is-exiting");
            }, 200);
          } else {
            // $("#main").addClass("is-exiting");
          }
        },
      },
      onReady: {
        render: function ($container, $newContent) {
          $container.html($newContent);
        },
      },
      onAfter: function ($container, $newContent) {
        $(document).trigger("rebuild");

        var cf_selector = "div.wpcf7 > form";
        var cf_forms = $newContent.find(cf_selector);
        if (cf_forms.length > 0) {
          $(cf_selector).each(function () {
            var $form = $(this);
            wpcf7.init($form[0]);
          });
        }

        setTimeout(function () {
          // $("#main").removeClass("is-exiting");
        }, 350);
      },
    };

    $("#main").smoothState(options).data("smoothState");
  },
};

export default pageTransition;
